import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useEffect, useState, useContext } from 'react';
import EmailModal from '../../components/modal/Email';
import { checkCustomer } from "../../http/customerAPI";
import { getMaterials } from "../../http/materialAPI";
import { getWorkTypes, getReability } from "../../http/worksAPI";
import House from './House';
import Work from './Work';
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { AMPLIFICATION, REABILITY } from '../../utils/constant';



export const Calculator = observer(() => {
    const { estimate: store } = useContext(Context)
    console.log('Calculator');

    const [house, setHouse] = useState({});
    const [work, setWork] = useState({});

    const [materials, setMaterials] = useState([]);
    const [workTypes, setWorkTypes] = useState([]);
    const [reability, setReability] = useState([]);

    const [estimate, setEstimate] = useState(JSON.parse(localStorage.getItem('estimate') || {}));

    const [open, setOpen] = useState(false);
    const [sumMaterial, setSumMaterial] = useState('');
    const [sumWork, setSumWork] = useState('');

    useEffect(() => {
        console.log('Calculator useEffect estimate', estimate);
        checkCustomer()

        getMaterials().then(res => setMaterials(res))
        getWorkTypes().then(res => setWorkTypes(res))
        getReability().then(res => setReability(res))


        let est = JSON.parse(localStorage.getItem('estimate') || {})

        setEstimate(est)

        // setEstimate(store.estimate)
        // console.log('useEffect', JSON.parse(localStorage.getItem('estimate')))

        // storeEstimate.setEstimate(est)

        // .then(
        //     () => {
        //         let est = JSON.parse(localStorage.getItem('estimate'))

        //         // setHouse(est?.house || {})
        //         // setWork(est?.work || {})
        //     }
        // )


        setHouse(est?.house)
        setWork(est?.work)
    }, [])

    useEffect(() => {
        console.log('Calculator useEffect house, work', estimate);
        localStorage.setItem('estimate', JSON.stringify(estimate))
        // store.setEstimate(estimate)
    }, [estimate])

    useEffect(() => {

        // const { base, wall } = house;
        // const { length = 0, width = 0, base, wall } = house;
        const { reliability = REABILITY[0], amplification = AMPLIFICATION[0], stove = false } = work;

        let min = Math.min(house?.length, house?.width)
        let max = Math.max(house?.length, house?.width)

        let balk = { ...materials.find(it => it.type === 'balk') }
        balk.length = min + 0.8
        balk.lengthUnit = Math.ceil(balk.length / 0.5) / 2
        balk.count = Math.ceil(max / reliability?.value) + 1
        balk.sum = balk?.count * balk?.lengthUnit * balk?.price

        let pile = { ...materials.find(it => it.type === 'pile') }
        pile.balkCount = Math.trunc(balk.lengthUnit / 3.75) + 1
        pile.count = balk.count * pile.balkCount
        pile.sum = pile?.count * pile?.price

        pile.cap = { ...materials?.find(it => it.type == 'capping') }
        pile.cap.count = work?.amplification?.name === "Оголовок" ? (pile?.balkCount <= 2 ? pile?.count : 2 * balk?.count) : 0
        pile.cap.sum = pile?.cap?.count * pile?.cap?.price

        let capping = { ...materials?.find(it => it.type == 'capping') }
        capping.count = work?.amplification?.name === "Оголовок" ? (pile?.balkCount <= 2 ? pile?.count : 2 * balk?.count) : 0

        let channel = { ...materials.find(it => it.type === 'channel'), required: {}, additional: {} }
        channel.required.count = (Math.ceil((pile?.balkCount >= 3) ? (max + 2) * (pile?.balkCount - 2) : 0) / 0.5) / 2
        channel.additional.count = Math.ceil((work?.amplification?.name === "Швеллер" ? max * 2 : 0) / 0.5) / 2
        channel.count = channel.required.count + channel.additional.count
        channel.sum = (channel?.required?.count + channel?.additional?.count) * channel?.price

        let cps = { ...materials.find(it => it.type === 'cps') }
        cps.count = Math.ceil(pile.count * 1.2)
        cps.sum = cps?.count * cps?.price

        let base = {}
        base.sum = house?.base ? house?.base?.price : 0
        let wall = {}
        wall.sum = house?.wall ? house?.wall?.price : 0

        let material = balk.sum + pile.sum + channel.sum + cps.sum + pile.cap.sum


        let balkWork = {...workTypes?.find(it => it.wid == 'balk')}
        balkWork.sum = balkWork.price
        let additionalBalkWork = {...workTypes?.find(it => it.wid == 'add_balk')}
        additionalBalkWork.sum = Number((balk.count > 4) ? (balk?.count - 4) * additionalBalkWork?.price : 0)
        let channelWork = {...workTypes?.find(it => it.wid == 'channel')}
        channelWork.sum = (channel?.required?.count + channel?.additional?.count) * channelWork?.price
        let stoveWork = {...workTypes?.find(it => it.wid == 'stove')}
        stoveWork.sum = (work?.stove ? stoveWork?.price : 0)

        let workPrice = balkWork.sum + additionalBalkWork.sum + channelWork.sum + stoveWork.sum + base.sum + wall.sum

        setEstimate({
            house,
            materials,
            material: {
                balk: { ...materials.balk, ...balk },
                pile: { ...materials.pile, ...pile },
                cps: { ...materials.cps, ...cps },
                channel: { ...materials.channel, ...channel },
                cap: { ...materials.capping, ...pile.cap }
            },
            work: {
                ...work,
                balk: { ...balk, ...balkWork },
                add_balk: { ...additionalBalkWork, count: Number((balk.count > 4) ? (balk.count - 4) * additionalBalkWork?.price : 0) },
                channel: { ...channelWork, count: channel.required.count + channel?.additional?.count },
                base: { ...house?.base, ...base },
                wall: { ...house?.wall, ...wall },
                stove: work?.stove && stoveWork,
            }
        })

        setSumMaterial(material)
        setSumWork(workPrice * 2)

    }, [work, house, materials, workTypes])


    return (
        <Container maxWidth="xs" sx={{ padding: 0 }}>

            <>
                <House house={house} work={work} setHouse={setHouse} />
                <Work workTypes={workTypes} work={work} setWork={setWork} />
            </>

            {(house?.length && house?.width) &&
                <>
                    <Box sx={{ minWidth: 120, 'textAlign': 'center' }} mt={2} visibility={false}>
                        <h3 className='sum' >Материалы {sumMaterial.toLocaleString() + ' ₽'}</h3>
                    </Box>
                    <Box sx={{ minWidth: 120, 'textAlign': 'center' }} mt={2} visibility={false}>
                        <h3 className='sum' >Работа {sumWork.toLocaleString() + ' ₽'}</h3>
                    </Box>
                    <Box sx={{ minWidth: 120 }} mt={2} visibility={false}>
                        <Button
                            variant="outlined"
                            sx={{ width: '100%' }}
                            onClick={() => setOpen(true)}
                        >
                            Получить подробную смету
                        </Button>
                    </Box>
                </>
            }

            <EmailModal
                open={open}
                setOpen={setOpen}
                house={house}
                work={work}
                estimate={estimate}
            />
        </Container >
    )
})
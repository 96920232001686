import React, { useContext, useState } from 'react'
import { Box, TextField, Button } from '@mui/material';
import { observer } from "mobx-react-lite";
import { OFFICE_ROUTE } from '../utils/consts'
import { login } from "../http/userAPI";
import { Context } from "../index";
import { NavLink, useHistory, useLocation } from "react-router-dom";


const Auth = observer(() => {
    const { user } = useContext(Context)
    const location = useLocation()
    const history = useHistory()

    const [name, setName] = useState('');
    const [pass, setPass] = useState('');

    const handleLogin = async () => {
        try {
            let data;

            data = await login(name, pass);

            user.setUser(data)
            user.setIsAuth(true)
            history.push(OFFICE_ROUTE)
        } catch (e) {
            alert(e.response.data.message)
        }
    }

    return (
        <Box
            sx={{
                width: 500,
                maxWidth: '100%',
            }}
        >
            <Box mt={2} fullWidth>
                <TextField
                    sx={{ width: '100%' }}
                    id="outlined-basic"
                    label="name"
                    variant="outlined"
                    // type="number"
                    onChange={e => setName(e.target.value)}
                    value={name}
                />
            </Box>
            <Box mt={2} fullWidth>
                <TextField
                    sx={{ width: '100%' }}
                    id="outlined-basic"
                    label="pass"
                    variant="outlined"
                    type="password"
                    onChange={e => setPass(e.target.value)}
                    value={pass}
                />
            </Box>
            <Box sx={{ minWidth: 120 }} mt={2} visibility={false}>
                <Button
                    variant="outlined"
                    sx={{ width: '100%' }}
                    onClick={handleLogin}
                >
                    login
                </Button>
            </Box>
        </Box>
    )
});

export default Auth;
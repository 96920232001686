import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { sendEstimate } from "../../http/estimateAPI";

export default function Email({ open, setOpen, house, work, estimate }) {
  const [email, setEmail] = React.useState('');

  console.log('estimate', estimate);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const hamdleSend = () => {
    sendEstimate({ house, estimate, email })
    setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Для получения сметы введите свой email</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
           Для получения смета введите свой email
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={hamdleSend}>Получить смету</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

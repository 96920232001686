import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./AppRouter";
import Box from '@mui/material/Box';
import Menu from './menu';
import { Container } from '@mui/material';
import { observer } from "mobx-react-lite";
import { Context } from "./index";
import { check } from "./http/userAPI";

const App = observer(() => {
    console.log('App');
    const { user, estimate } = useContext(Context)
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        check().then(data => {
            user.setUser(data)
            user.setIsAuth(true)
            console.log('check');
        }).finally(() => setLoading(false))
    }, [user])

    useEffect(() => {
        let est = JSON.parse(localStorage.getItem('estimate'))
        // setEstimate(est)

        console.log('useEffect', JSON.parse(localStorage.getItem('estimate')))

        estimate.setEstimate(est)

    }, [])

    // if (loading) {
    //     return <></>
    // }

    return (
        <BrowserRouter>
            {/* <Box sx={{ display: 'flex' }}> */}
            <Menu />
            <Container sx={{ padding: 0, paddingTop: '70px' }}>
                <AppRouter />
            </Container>
            {/* </Box> */}
        </BrowserRouter>
    );
});

export default App;

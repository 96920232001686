import { observer } from "mobx-react-lite";
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Context } from "./index";
import { authRoutes, publicRoutes } from "./routes";

const AppRouter = observer(() => {
    console.log('AppRouter');
    const { user } = useContext(Context)

    return (
        <Switch>
            {user.isAuth && authRoutes.map(({ path, Component }) =>
                <Route key={path} path={path} component={Component} exact />
            )}
            {publicRoutes.map(({ path, Component }) =>
                <Route key={path} path={path} component={Component} exact />
            )}
            {/* <Redirect to={MAIN_ROUTE} /> */}
        </Switch>
    );
});

export default AppRouter;

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import React, { useState, useEffect } from 'react';
import { AMPLIFICATION, REABILITY } from '../../utils/constant';


export const Work = ({ workTypes, work, setWork }) => {
    const [reliability, setReliability] = useState(REABILITY[0]);
    const [amplification, setAmplification] = useState(workTypes.find(it => it.wid === 'capping'));
    const [stove, setStove] = useState(false)

    // useEffect(() => {
    //     getDictionary().then(({ houseBase, houseWall }) => {
    //         setBaseList(houseBase)
    //         setWallList(houseWall)
    //     })

    //     setLength(house.length)
    //     setWidth(house.width)

    //     setIsLoad(true)
    // }, [])

    useEffect(() => {
        // setReliability(workTypes.find(it => it.id === work.reliability.id)
        console.log('workTypes', workTypes.find(it => it.id === work?.amplification?.id), work?.amplification);
        setAmplification(workTypes.find(it => it.id === work?.amplification?.id))
        // setAmplification(work.amplification)
        setStove(!!work?.stove)
    }, [work, workTypes])

    const handleReliabilityChange = (e) => {
        setReliability(e.target.value);
        setWork({ ...work, reliability: e.target.value })
    };

    const handleHeaderChange = (e) => {
        setAmplification(e.target.value);
        setWork({ ...work, amplification: e.target.value })
    };

    return (
        <>
            <Box sx={{ minWidth: 120 }} mt={2}>
                <FormControl fullWidth>
                    <InputLabel id="reliability">Надежность</InputLabel>
                    <Select
                        labelId="reliability"
                        id="reliability"
                        value={reliability || ''}
                        label="Надежность"
                        onChange={handleReliabilityChange}
                    >
                        {REABILITY.map(it => <MenuItem value={it} key={it.id}>{it.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>

            <Box sx={{ minWidth: 120 }} mt={2}>
                <FormControl fullWidth>
                    <InputLabel id="header">Усилене внешних свай</InputLabel>
                    <Select
                        labelId="header"
                        id="header"
                        value={amplification || ''}
                        label="Усилене внешних свай"
                        onChange={handleHeaderChange}
                    >
                        {workTypes.filter(it => it.type === 'amplification').map(it => <MenuItem value={it} key={it.id}>{it.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>

            {/* <Box sx={{ minWidth: 120 }} mt={2}>
                                <FormGroup mt={2}>
                                    <FormControlLabel control={<Switch />} label="Замена венца" />
                                </FormGroup>
                            </Box> */}

            <Box sx={{ minWidth: 120 }} mt={2}>
                <FormGroup mt={2}>
                    <FormControlLabel control={<Switch checked={stove} onChange={() => { setStove(!stove); setWork({ ...work, stove: !work.stove }) }} />} label="Печь на отдельном фундаменте" />
                </FormGroup>
            </Box>

            {/* <Box sx={{ minWidth: 120 }} mt={2}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Договор в течение 3 дней" />
                                </FormGroup>
                            </Box> */}
        </>
    )
}

export default Work

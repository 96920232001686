import Auth from "./office/Auth";
import { Calculator } from "./pages/Calculator/Calculator";
import { Estimate } from "./pages/Estimate";
import Contact from "./pages/Contact";
import Office from "./office/Office";
import {
    AUTH_ROUTE,
    MAIN_ROUTE,
    OFFICE_ROUTE,
    ESTIMATE_ROUTE,
    CONTACT_ROUTE
} from "./utils/consts";


export const authRoutes = [
    {
        path: OFFICE_ROUTE,
        Component: Office
    }
]

export const publicRoutes = [
    {
        path: AUTH_ROUTE,
        Component: Auth
    },
    {
        path: MAIN_ROUTE,
        Component: Calculator
    },
    {
        path: ESTIMATE_ROUTE,
        Component: Estimate
    },
    {
        path: CONTACT_ROUTE,
        Component: Contact
    }
]

import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Context } from "./index";
import { ESTIMATE_ROUTE, MAIN_ROUTE, OFFICE_ROUTE, CONTACT_ROUTE } from './utils/consts';


export default function Menu() {
    const { user } = useContext(Context)
    const history = useHistory()
    const [state, setState] = React.useState({ top: false });
    const [open, setOpen] = React.useState(false);

    console.log('Menu', user.isAuth);

    const toggleDrawer = (anchor, open) => (event) => {
        setOpen(true);

        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            // sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            // role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            {/* <CssBaseline /> */}
            <List>
                {/* {['Контакты'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemText primary={text} />
                    </ListItem>
                ))} */}
                 <ListItem button key={'Контакты'}>
                        <ListItemText primary={'Контакты'} onClick={() => history.push(CONTACT_ROUTE)} />
                    </ListItem>
                <ListItem button key={'Смета'}>
                    <ListItemText primary={'Смета'} onClick={() => history.push(ESTIMATE_ROUTE)} />
                </ListItem>
                {user.isAuth &&
                    <ListItem button key={'Кабинет'}>
                        <ListItemText primary={'Кабинет'} onClick={() => history.push(OFFICE_ROUTE)} />
                    </ListItem>
                }
            </List>
        </Box >
    );

    let anchor = 'top'

    return (
        <AppBar
            position="fixed"
            color="primary">
            <Toolbar>
                <Typography variant="h6" component="div"
                    sx={{ flexGrow: 1, cursor: 'pointer' }}
                    onClick={() => history.push(MAIN_ROUTE)}

                >
                    Дом на Подъем
                </Typography>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer(anchor, true)}
                // sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>
            <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
            >
                {list(anchor)}
            </Drawer>
        </AppBar>
    );
}

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { callRequest } from "../../http/estimateAPI";

export default function FormDialog({ open, setOpen }) {
  const [phone, setPhone] = React.useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const hamdleSend = () => {
    callRequest({ phone })
    setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Заказ обратного звонка</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
           Для получения смета введите свой email
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Phone"
            type="phone"
            fullWidth
            variant="standard"
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={hamdleSend}>Обратный звонок</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

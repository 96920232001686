
import { $host } from "./index";


export const getMaterials = async () => {
    const { data } = await $host.get('api/material/getMaterials')

    return data
}

export const updateMaterials = async (materials) => {
    const { data } = await $host.post('api/material/updateMaterials', { materials })

    return data
} 
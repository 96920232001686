
import { $host } from "./index";


export const getReability = async () => {
    const { data } = await $host.get('api/work/getReability')

    return data
}

export const getWorkTypes = async () => {
    const { data } = await $host.get('api/work/getWorkTypes')

    return data
}

export const updateWorksTypes = async (workTypes) => {
    const { data } = await $host.post('api/work/updateWorksTypes', { workTypes })

    return data
}

import { $host } from "./index";


export const checkCustomer = async () => {
    let uid = localStorage.getItem('uid')

    if (uid) {
        const { data } = await $host.get('api/customer/update', { params: { uid } })

        return data
    } else {
        const { data } = await $host.get('api/customer/add')

        localStorage.setItem('uid', data.id)

        return data
    }
}

export const getAll = async () => {
    const { data } = await $host.get('api/customer/getAll')

    return data
}


import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import React, { useState } from 'react';


export const Estimate = observer(() => {
  const [estimate, setEstimate] = useState(JSON.parse(localStorage.getItem('estimate') || {}));


  // const { estimate } = useContext(Context)

  console.log('Estimate',);

  return (
    <>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {Object.values(estimate?.work)?.filter(it => it.sum).map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.sum}</TableCell>
                  {/* <TableCell align="right">{row.count}</TableCell> */}
                </TableRow>
              ))}
               <TableRow>
                {/* <TableCell rowSpan={2} /> */}
                <TableCell colSpan={1} align="right">Итого</TableCell>
                <TableCell align="right">
                  {Object.values(estimate?.work)?.filter(it => it.sum).reduce((p, n) => p + n.sum, 0)}  * 2
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box mt={2}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell align="дуае">
                  Наименование
                </TableCell>
                <TableCell align="right">Цена</TableCell>
                <TableCell align="right">Количество</TableCell>
                <TableCell align="right">Сумма</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(estimate?.material)?.filter(it => it.price && it.count).map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.price}</TableCell>
                  <TableCell align="right">{row.count}</TableCell>
                  <TableCell align="right">{row.sum}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                {/* <TableCell rowSpan={2} /> */}
                <TableCell colSpan={3} align="right">Итого</TableCell>
                <TableCell align="right">
                  {Object.values(estimate?.material)?.filter(it => it.price && it.count).reduce((p, n) => p + n.sum, 0)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>

  );
})

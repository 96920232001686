import {makeAutoObservable} from "mobx";

export default class EstimateStore {
    constructor() {
        this._estimate = {}
        makeAutoObservable(this)
    }

    setEstimate(estimate) {
        this._estimate = estimate
        localStorage.setItem('estimate', JSON.stringify(estimate))
    }

    get estimate() {
        return this._estimate
    }
}

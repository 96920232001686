export const PRICE = [
    { ruName: 'Cвая', name: '', price: 3600, unit: 'штук', description: '' },
    { ruName: 'Балка', name: '', price: 2700, unit: 'штук', description: '' },
    { ruName: 'Оголовок', name: '', price: 300, unit: 'штук', description: '' },
    { ruName: 'Швеллер', name: '', price: 1900, unit: 'штук', description: '' },
    { ruName: 'Венец', name: '', price: 3800, unit: 'штук', description: '' },
    { ruName: 'ЦПС', name: '', price: 150, unit: 'мешок', description: 'Цементно песчая смесь' },

]

export const BASE_FORM = [
    { id: 1, name: 'Прямоугольник' },
    { id: 2, name: 'Сложная' }
]

export const FOUNDATION = [
    { name: 'Блоки бетонные покупные', price: 3000 },
    { name: 'Блоки заводские армированные', price: 500, isUnitPrice: true },
    { name: 'Лента', price: 5000 },
    { name: 'Сваи', price: 0 },
    { name: 'Без фундамента(с земли)', price: 5000 },
]


// todo каркас трудность работ зависит от материала и от перимитра
export const WALL = [
    { name: 'Бревно сруб', price: 0 },
    { name: 'Брус', price: 0 },
    { name: 'Каркас свежий(до 5 лет)', price: 0 },
    { name: 'Каркас плохое состояние', price: 15000 },
    { name: 'Карасно-щитовой с наполнением', price: 20000 },
]

export const REABILITY = [
    { id: 1, name: 'Стандартная', price: 0, value: 2.5 },
    { id: 2, name: 'Повышенная надежность', price: 0, value: 2 }
]

export const AMPLIFICATION = [
    { id: 1, name: 'Оголовок', unit: 'штука', price: 300 },
    { id: 2, name: 'Швеллер', unit: 'метр', price: 1900 }
]
import {$authHost, $host} from "./index";
import jwt_decode from "jwt-decode";

export const login = async (name, password) => {
    const {data} = await $host.post('api/user/login', {name, password})
    localStorage.setItem('token', data.token)
    return jwt_decode(data.token)
}

export const check = async () => {
    const {data} = await $authHost.get('api/user/auth')
    localStorage.setItem('token', data.token)
    return jwt_decode(data.token)
}

export const logout = () => {
    localStorage.removeItem('token')
}

export const getProfile = async () => {
    const {data} = await $authHost.get('api/user/profile')
    
    return data
}

export const updateInfo = async (person) => {
    const {data} = await $authHost.post('api/user/update', {person})
    
    return data
}
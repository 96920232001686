import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import { getDictionary } from "../../http/houseAPI";
import { BASE_FORM } from '../../utils/constant';

const House = ({ house, setHouse }) => {
    console.log('House');
    const [isLoad, setIsLoad] = useState(false)

    // house
    const [length, setLength] = useState({});
    const [width, setWidth] = useState({});

    // house base
    const [base, setBase] = useState(null); // { name: '', price: 0 }
    const [baseList, setBaseList] = useState([]);

    // house wall
    const [wall, setWall] = useState(null);
    const [wallList, setWallList] = useState([]);

    // house form
    // const [baseForm, setBaseForm] = useState(BASE_FORM[0]);
    // const [formList, setFormList] = useState([]);

    useEffect(() => {
        getDictionary().then(({ houseBase, houseWall }) => {
            setBaseList(houseBase)
            setWallList(houseWall)
            // setFormList(res.houseForm)

            // console.log('house.base', house.base.id, res.houseBase, res.houseBase.find(it => it.id === house.base.id));

            // setBase(houseBase.find(it => it.id == house.base.id))
            // setWall(houseWall.find(it => it.id == house.wall.id))
        })

        // console.log('house', house);

        setLength(house.length)
        setWidth(house.width)

        setIsLoad(true)

    }, [])

    useEffect(() => {
        setLength(house?.length || '')
        setWidth(house?.width || '')

        setBase(baseList.find(it => it.id === house?.base?.id))
        setWall(wallList.find(it => it.id === house?.wall?.id))

        // console.log('setBase', baseList, house?.base, baseList.find(it => it.id === house?.base.id));
    }, [house, baseList, wallList])

    const handleBaseChange = (e) => {
        console.log('handleBaseChange');
        setBase(e.target.value);
        setHouse({ ...house, base: e.target.value })
    };

    const handleWallChange = (e) => {
        setWall(e.target.value);
        setHouse({ ...house, wall: e.target.value })
    };

    // const handleBaseFormChange = (e) => {
    //     setBaseForm(e.target.value);
    //     setHouse({ ...house, baseForm: e.target.value })
    // };

    console.log('base', base);

    if (!isLoad)
        return <></>


    return (
        <>
            {/* <Box mt={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="base-form">Форма основания</InputLabel>
                                        <Select
                                            labelId="base-form"
                                            id="base-form"
                                            value={baseForm}
                                            label="Форма основания"
                                            onChange={handleBaseFormChange}
                                        >
                                            {baseList.map(it => <MenuItem value={it} key={it.id}>{it.name}</MenuItem>)}
                                        </Select>
                                    </FormControl >
                                </Box > */}
            <Stack direction="row" spacing={2} mt={2}>
                <Box sx={{ minWidth: 120 }}>
                    <TextField
                        id="outlined-basic"
                        label="Длина"
                        variant="outlined"
                        type="number"
                        InputProps={{ endAdornment: <InputAdornment position="end">м</InputAdornment> }}
                        InputLabelProps={{ shrink: true }}
                        onChange={e => { setLength(e.target.value); setHouse({ ...house, length: e.target.value }) }}
                        value={length || ''}
                    />
                </Box>
                <Box sx={{ minWidth: 120 }}>
                    <TextField
                        id="outlined-basic"
                        label="Ширина"
                        variant="outlined"
                        type="number"
                        InputProps={{ endAdornment: <InputAdornment position="end">м</InputAdornment> }}
                        InputLabelProps={{ shrink: true }}
                        onChange={e => { setWidth(e.target.value); setHouse({ ...house, width: e.target.value }) }}
                        value={width || ''}
                    />
                </Box>
            </Stack>

            <Box sx={{ minWidth: 120 }} mt={2}>
                <FormControl fullWidth>
                    <InputLabel id="base">Фундамент</InputLabel>
                    <Select
                        labelId="base"
                        id="base"
                        value={base || ''}
                        defaultValue=""
                        label="Фундамент"
                        onChange={handleBaseChange}
                    >
                        {baseList?.map(it => <MenuItem key={it.id} value={it}>{it.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>

            <Box sx={{ minWidth: 120 }} mt={2}>
                <FormControl fullWidth>
                    <InputLabel id="base">Материал стен</InputLabel>
                    <Select
                        labelId="wall"
                        id="wall"
                        value={wall || ''}
                        defaultValue=""
                        label="Материал стен"
                        onChange={handleWallChange}
                    >
                        {wallList?.map(it => <MenuItem key={it.id} value={it}>{it.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
        </>
    );
}

export default House

import { Box, Button } from '@mui/material';
import * as React from 'react';
import Phone from '../components/modal/Phone'

const Contact = () => {
    const [open, setOpen] = React.useState(false)

    return (
        <Box sx={{ width: '100%' }}>
            <h3>
                Контакты
            </h3>
            <p>
                Контактная информация
            </p>
            <Box sx={{ minWidth: 120 }} mt={2} visibility={false}>
                <Button
                    variant="outlined"
                    // sx={{ width: '100%' }}
                    onClick={() => setOpen(!open)}
                >
                    Обратный звонок
                </Button>
            </Box>
            <Phone
                open={open}
                setOpen={setOpen}
            />
        </Box>
    );
}

export default Contact


import { $host } from "./index";


export const sendEstimate = async ({ house, estimate, email }) => {
    let uid = localStorage.getItem('uid')
    const { data } = await $host.post('api/estimate/create', { uid, house, estimate, email })

    return data
}
export const callRequest = async ({ phone }) => {
    let uid = localStorage.getItem('uid')
    const { data } = await $host.post('api/estimate/callRequest', { uid, phone })

    return data
}

import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './store/store'


export const Context = createContext(null)

ReactDOM.render(
  <Context.Provider value={store}>
    <App />
  </Context.Provider>,
  document.getElementById('root')
);

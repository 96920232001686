import { Box, TextField, InputAdornment, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { getMaterials, updateMaterials } from '../http/materialAPI';

export default function Materials({ }) {
  const [materials, setMaterials] = useState([])

  useEffect(() => {
    getMaterials().then(res => {
      setMaterials(res)
    })
  }, [])

  const handleSave = () => {
    updateMaterials(materials)
  }

  return (
    <div>
      {materials.map(it =>
        <Box mt={2} >
          <TextField
            // sx={{ width: '100%' }}
            id="outlined-basic"
            label={it.name}
            variant="outlined"
            type="number"
            onChange={e => setMaterials(materials => materials.map(mt => (mt.id == it.id) ? { ...mt, price: e.target.value } : mt))}
            InputProps={{ endAdornment: <InputAdornment position="end">₽</InputAdornment> }}
            InputLabelProps={{ shrink: true }}
            value={it.price}
          />
        </Box>
      )}
      {/* <Box mt={2} fullWidth>
        <TextField
          sx={{ width: '100%' }}
          id="outlined-basic"
          label="Свая"
          variant="outlined"
          type="number"
          onChange={e => setPilePrice(e.target.value)}
          InputProps={{ endAdornment: <InputAdornment position="end">₽</InputAdornment> }}
          InputLabelProps={{ shrink: true }}
          value={pilePrice}
        />
      </Box> */}
      <Box sx={{ minWidth: 120 }} mt={2} visibility={false}>
        <Button
          variant="outlined"
          // sx={{ width: '100%' }}
          onClick={handleSave}
        >
          Сохранить
        </Button>
      </Box>
    </div>
  );
}